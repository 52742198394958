import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Landingpage from './components/Landingpage';


function App() {
  
  return (      
    <Landingpage/>
    );
}

export default App;
