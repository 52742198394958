import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter as Router, Routes , Route } from "react-router-dom";
import Header from './components/Header';
import Products from './components/Products';
import AboutUs from './components/AboutUs';
import Career from './components/Career';
import Footer from './components/Footer';
import Services from './components/Services';
import Signin from './components/Signin';
import ScrollToTop from './components/ScrollToTop';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Router>
     <ScrollToTop/>
      <Header/>     
      <Routes>
        <Route exact path="/" element={<App/>} />
        <Route path="/products" element={<Products/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/aboutus" element={<AboutUs/>} />
        <Route path="/career" element={<Career/>} />       
        <Route path="/signin" element={<Signin/>} />        
      </Routes>
    </Router>
   <Footer/>
  </React.StrictMode>
);

reportWebVitals();
