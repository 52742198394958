import React from "react";
import { Container, Card, Carousel, Row, Col } from "react-bootstrap";
import background from "../videos/background.mp4";
import imagArray from "./Images";
import MarqueeText from "./MarqueeText";

const Landingpage = () => {
  return (
    <>
      <div className="bgvideo embed-responsive embed-responsive-16by9 mb-3">
        <video loop autoPlay muted>
          <source src={background} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <MarqueeText />
      <Container className="mb-5" id="dtblock">
        <Card>
          <Card.Body>
            <h4>Smart Sodar - Digital Twin</h4>
            <hr />

            <p>
              Emerging Industrialization 4.0, digital twin is one of the key
              technologies taking the front seat for design, manufacture and
              maintaining highly complex systems in real time.
            </p>
            <p>
              Sodar one such a complex system and required regular monitoring
              and maintenance in order to attain a high degree of accuracy of
              various parameters of the system. Another crucial part of this
              remote sensing system is to maintain it's beamforming capability
              by protecting the individual sensor elements from its system of
              sensor elements, so that it doesn't lose the desired shape of the
              radiation pattern(digital beamforming capability).
            </p>
            <p>
              By adopting the state-of-the-art digital twin technology, the
              system can be accessed remotely and monitor it's key parameters on
              a day-to-day basis. This Technology implementation enables the
              Scientist/Operator to carry out the maintenance related system -
              selftests and also to monitor the site environment, which greatly
              influences the measurements of intended parameters. The present
              digital twin of the Smart Sodar is being developed with the
              following features.
            </p>
            <ul className="mb-0">
              <li>System Performance Monitoring</li>
              <li>Real time site assessment</li>
              <li>Realtime bi-directional data transfer</li>
            </ul>
          </Card.Body>
          <Carousel id="dtslides">
            <Carousel.Item>
              <img
                className="d-block w-75"
                src={imagArray["AntennaTwin1.png"]}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-75"
                src={imagArray["AntennaTwin2.png"]}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-75"
                src={imagArray["AntennaTwin3.png"]}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </Card>
      </Container>
      <Container id="sodarblock">
        <Card>
          <Card.Body>
            <h4>Smart Environment</h4>
            <hr />
            <p>
              One of the key renewable energy sources in the following years
              would be the wind energy. Approximately 2% of the solar energy
              striking the Earths&rsquo;s surface is converted into kinetic
              energy in wind. Wind turbines convert the wind&rsquo;s kinetic
              energy to electricity without emissions
              <a href="https://css.umich.edu/sites/default/files/Wind%20Energy_CSS07-09_e2020.pdf">
                [1]
              </a>
              . Global onshore and offshore wind power potential at Commercial
              turbine hub heights could provide 840,000 TWh of electricity
              annually. Total global electricity consumption from all sources in
              2017 was about 22,347 TWh.
            </p>
            <p>
              SODAR&nbsp;(<u>SO</u>und <u>D</u>etection&nbsp; <u>A</u>nd&nbsp;
              <u>R</u>anging) is a system which esses the wind power mill
              installation site for the worthiness of the power plant
              deployment.
            </p>
          </Card.Body>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-75"
                src={imagArray["Fascimal.png"]}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-75"
                src={imagArray["TDGrid.png"]}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-75"
                src={imagArray["EcoSpectrum.png"]}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </Card>
      </Container>
      <Container className="mb-5 mt-4" id="swblock">
        <Card>
          <Card.Body>
            <Row>
              <Col lg={7}>
                <h4>Digital Transformation </h4>
                <hr />
                <br />
                <ul>
                  <li>
                    Leveraging AI for seamless integration with 3D models in BIM
                    processes.
                  </li>
                  <li>
                    Innovative integration techniques for computer-aided design
                    and engineering tools.
                  </li>
                  <li>
                    Expertise in mapping and developing chamber interiors.
                  </li>
                  <li>
                    Proficiency in handling point clouds from diverse sensors.
                  </li>
                  <li>
                    Offering engineering support to leading industrial entities.
                  </li>
                </ul>
              </Col>
              <Col lg={5}>
                <img
                  alt="IOT"
                  className="rounded img-thumbnail "
                  src={imagArray["digitization.jpg"]}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <img
        className="d-block w-100"
        height="400"
        src={imagArray["mobile.jpg"]}
        alt="Mobile"
      />
    </>
  );
};
export default Landingpage;
