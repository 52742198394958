import React from 'react';
import { Container,Row,Col} from 'react-bootstrap';
import imagArray from "./Images";
const AboutUs = () => {
    return ( 
   <Container className='py-5' id='about'>
       <Row className='mt-5'>
           <Col lg={6}>
           <p>
            With current trends in the Digital Transformation of all major industries, in general, it should be more in developing new solutions to reduce the investment costs for digitization solutions. According to Mordor Intelligence, the global digital transformation market will be three folds of today's growth rate by 2026. New digitized business models are very efficient in facilitating more technologically advanced products and services. These digitally transformed organizations can adopt to the evolving technological landscape and can tackle sudden shifts in the industry. But present market has not fully prepared for the transition and there is huge scope for new startup companies to work on cutting edge technologies to provide innovative solutions for these market requirements. Here the Ecorvi, creates it own market space in providing the innovative technological solutions and services to various Industrial Organizations and Small Business Units. 
          </p>
           </Col>
           <Col lg={6}>
           <img src={imagArray["clock.jpg"]} className="d-block w-100" alt="Cinque Terre" width="450" height="350"/>
           </Col>
       </Row>
       <Row className='mt-3'>
       <p>Our major area of focus is on Industrial Digital Transformation, Smart Cites and IoT technology solutions. We also extends our services for website building to Small Enterprises and Academic Institutions.</p>
       </Row>
   </Container>
     );
}
 
export default AboutUs;
