import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import imagArray from "./Images";
import JobPost from "./JobPost";
const Career = () => {
  return (
    //    <Container className='py-5 mt-5'>
    //        <Row >
    //            <Col lg={8}>
    //             <img src={imagArray['Comingsoon.jpg']} alt="Coming soon" className="d-block img-responsive w-100" height='400' />
    //            </Col>
    //            <Col lg={4}>
    //            <img src={imagArray['comingsoon_2.jpg']} alt="Coming soon" className="d-block w-100" height='400'/>
    //            </Col>
    //        </Row>
    //    </Container>
    <JobPost />
  );
};

export default Career;
