import React from "react";
import Ecorvi_Logo from "../images/Ecorvi_Logo.png";
import "../styles/navStyle.css";
import { Nav, Navbar} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Header = () => {
    return (  <Navbar
        collapseOnSelect
        expand="xl"
        fixed="top"
        bg="secondary"
        variant="dark"
      >
        <LinkContainer to="/">
          <Navbar.Brand href="#home">
            <img
              className="logo"
              src={Ecorvi_Logo}
              width="100"
              heigth="30"
              alt="EcorviLogo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="w-100 justify-content-end">
            <LinkContainer to="/">
              <Nav.Link className="navLink">Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/aboutus">
                <Nav.Link className="navLink">About Us</Nav.Link>             
            </LinkContainer>
            <LinkContainer to="/products">
            <Nav.Link className="navLink">Products</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/services">
            <Nav.Link className="navLink">Services</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/career">
              <Nav.Link className="navLink">Career</Nav.Link>
            </LinkContainer>
         
              <Nav.Link className="navLink" href="#mainfooter">Contact</Nav.Link>
            
            <LinkContainer to="/signin">
              <Nav.Link className="navLink">
                Sign in{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  className="bi bi-person"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                </svg>
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar> );
}
 
export default Header;
    
  