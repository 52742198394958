import React from "react";
 // eslint-disable-next-line to the line before.
const MarqueeText = () => {
  return (
    <div id="text">
      <marquee className="marq" direction="up" loop="" scrollamount="5">
        <div className="geek1">Digital Twins</div>
        <br />
        <div className="geek1">Industrial IoT</div>
        <br />        
      </marquee>
     
    </div>
  );
};

export default MarqueeText;
